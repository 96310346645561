import { Box, useTheme, Dialog, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SelectProduct from "../select/SelectProduct";
import SelectService from "../select/SelectService";
import Header from "./position-modules/Header";
import Entry from "./position-modules/Entry";
import Sum from "./position-modules/Sum";
import Controls from "./position-modules/Controls";
import { numberToString } from "../special/numberConverter";
import Trans from "../special/Trans";
import { useTranslation } from "react-i18next";
import ExoDialog from "./ExoDialog";
import { EntryContainer } from "./position-modules/EntryContainer";
import ExoAvatar from "./ExoAvatar";

const ExoPositions = forwardRef(
  (
    {
      onPositionChange,
      startPositions,
      onFocus = () => {},
      descriptionVars = [],
      showVars,
      hideServices,
      hideProducts,
      hideNewPosition,
      hideSum,
      emptyStart,
      columns = [
        {
          text: Trans("Position"),
          width: "80px",
          align: "left",
          key: "position",
          className: "monospace justify-center",
          number: true,
          decimalPlaces: 2,
          editable: false,
          hidden: false,
          default: 1,
        },
        {
          text: Trans("Description"),
          width: "auto",
          align: "left",
          key: "name",
          editable: true,
          hidden: false,
          default: "",
        },
        {
          text: Trans("Amount"),
          width: "80px",
          align: "center",
          key: "amount",
          className: "monospace",
          number: true,
          decimalPlaces: 2,
          editable: true,
          hidden: false,
          default: 0,
        },
        {
          text: Trans("Unit"),
          width: "80px",
          align: "right",
          key: "unit",
          className: "justify-center",
          editable: true,
          hidden: false,
          default: "",
        },
        {
          text: Trans("Tax Rate"),
          width: "100px",
          align: "right",
          key: "taxRate",
          className: "monospace",
          unit: " %",
          number: true,
          decimalPlaces: 0,
          editable: true,
          hidden: false,
          default: 19,
        },
        {
          text: Trans("Price"),
          width: "120px",
          align: "right",
          key: "price",
          className: "monospace",
          unit: " €",
          number: true,
          editable: true,
          hidden: false,
          default: 0,
        },
        {
          text: Trans("Sum"),
          width: "120px",
          align: "right",
          key: "custom",
          unit: " €",
          className: "justify-end",
          hidden: false,
          custom: (element, unit) => {
            var sum = 0;
            if (element.amount && element.price) {
              sum = (
                parseFloat(element.amount) * parseFloat(element.price)
              ).toFixed(2);
            }
            return (
              <Box className="w-full text-right monospace">
                {numberToString(sum)}
                {unit}
              </Box>
            );
          },
        },
        {
          key: "status",
          hidden: true,
          default: 0,
        },
        {
          key: "description",
          hidden: true,
          default: "",
        },
        {
          key: "positionableId",
          hidden: true,
          default: null,
        },
        {
          key: "positionableType",
          hidden: true,
          default: "custom",
        },
      ],
      allowImages,
    },
    ref
  ) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

    const { t } = useTranslation();

    const [items, setItems] = useState([]);
    const [defaultPosition, setDefaultPosition] = useState(null);

    const [sum, setSum] = useState({
      netto: 0,
      tax: 0,
    });

    useEffect(() => {
      if (startPositions && startPositions.length > 0) setItems(startPositions);
    }, [startPositions]);

    useEffect(() => {
      var newDefaultPositions = {};
      columns.forEach((column) => {
        newDefaultPositions[column.key] = column.default;
      });
      setDefaultPosition(newDefaultPositions);
    }, []);

    useEffect(() => {
      recalculateSum(items);
    }, [items]);

    useEffect(() => {
      if (emptyStart) return;
      if (items.length === 0 && defaultPosition) {
        handleNewPosition(defaultPosition);
      }
    }, [defaultPosition]);

    function recalculateSum(newItems) {
      var newSum = 0;
      var newTax = 0;
      newItems.forEach((element) => {
        newSum += element.amount * element.price;
        newTax += element.amount * element.price * (element.taxRate / 100);
      });
      setSum({
        netto: newSum,
        tax: newTax,
      });
    }

    // reorder Positions
    function orderPositions(pos) {
      var newItems = [...pos];
      for (let i = 0; i < newItems.length; i++) {
        newItems[i].position = i + 1;
      }
      return newItems;
    }

    // drag stuff
    const [isDragging, setIsDragging] = useState(false);

    const handleOnDragStart = (result) => {
      setIsDragging(true);
    };

    const handleOnDragEnd = (result) => {
      setIsDragging(false);
      const { source, destination } = result;
      // If item was dropped outside of a droppable, do nothing
      if (!destination) {
        return;
      }
      // If item was dropped in the "trash" droppable, delete the item
      var newItems = [...items];
      if (destination.droppableId === "trash") {
        newItems.splice(source.index, 1);
      } else {
        // Otherwise, reorder the items as usual
        const [removed] = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, removed);
      }
      const orderedPositions = orderPositions(newItems);
      setItems(orderedPositions);
    };

    function handleNewPosition(position) {
      var newItems = items;
      newItems[items.length] = { ...position };
      for (let i = 0; i < newItems.length; i++) {
        newItems[i].position = i + 1;
      }
      onPositionChange([...newItems]);
      setItems([...newItems]);
      recalculateSum([...newItems]);
    }

    // product
    const [openProducts, setOpenProducts] = useState(false);
    function handleProductSelect(product) {
      const productEntry = {
        productNr: product.nr,
        status: 0,
        position: 1,
        name: product.name,
        description: product.description,
        amount: 0,
        unit: product.unit,
        price: product.price,
        taxRate: product.taxRate,
        positionableId: product.id,
        positionableType: "product",
        taskTitle: product.name,
        pricePerHour: product.price,
        monthlyLimit: 0,
      };
      handleNewPosition(productEntry);
    }

    // service
    const [openServices, setOpenServices] = useState(false);
    function handleServiceSelect(service) {
      const serviceEntry = {
        serviceNr: service.nr,
        status: 0,
        position: 1,
        name: service.name,
        description: service.description,
        amount: 0,
        unit: service.unit,
        price: service.price,
        taxRate: service.taxRate,
        positionableId: service.id,
        positionableType: "service",
        taskTitle: service.name,
        pricePerHour: service.price,
        monthlyLimit: 0,
      };
      handleNewPosition(serviceEntry);
    }

    // exposed functions
    useImperativeHandle(ref, () => ({
      setItems: setItems,
      items: items,
    }));

    // position width
    const tabelContainerRef = useRef(null);
    const [tableWidth, setTableWidth] = useState(null);

    useEffect(() => {
      // Attach event listener for window resize
      const handleResize = () => {
        // Update containerWidth when the container is resized
        if (tabelContainerRef.current) {
          const containerWidth = tabelContainerRef.current.offsetWidth;
          setTableWidth(containerWidth);
        }
      };

      window.addEventListener("resize", handleResize);

      // Initial setup
      handleResize();

      // Cleanup the event listener when the component is unmounted
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [tabelContainerRef]);

    return (
      <>
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onDragStart={handleOnDragStart}
        >
          <Box
            className="relative"
            sx={{
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
              "& .MuiAccordionSummary-root": {
                minHeight: "26px",
              },
              "& th:first-child": {
                borderTopLeftRadius: "8px",
              },
              "& th:last-child": {
                borderTopRightRadius: "8px",
              },
              "& tr:last-child>td:first-child": {
                borderBottomLeftRadius: "8px",
              },
              "& tr:last-child>td:last-child": {
                borderBottomRightRadius: "8px",
              },
              "& td, th": {
                backgroundColor: colors.bgInput,
              },
            }}
          >
            <Droppable droppableId="positions">
              {(provided) => (
                <table
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="w-full"
                >
                  <tbody>
                    <tr>
                      {allowImages && <Header col={{}}>{t("Image")}</Header>}
                      {columns.map((col, index) => (
                        <Header key={"header-" + col.key} col={col}>
                          {col.text}
                        </Header>
                      ))}
                    </tr>

                    {items.length === 0 && (
                      <tr>
                        <td colSpan={9999} className=" h-10">
                          <Typography variant="body1" textAlign="center">
                            {t("No Position")}
                          </Typography>
                        </td>
                      </tr>
                    )}

                    {items.map((item, index) => (
                      <Draggable
                        key={"drag-" + item.id + "-" + index}
                        draggableId={"item-" + item.id + "-" + index}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            {allowImages && (
                              <EntryContainer col={{ className: "" }}>
                                <ExoAvatar size="30px" />
                              </EntryContainer>
                            )}
                            {columns.map((col, i) => (
                              <Entry
                                key={item.position + i}
                                col={col}
                                setItems={(newItems) => {
                                  setItems(newItems);
                                  recalculateSum(newItems);
                                  onPositionChange(newItems);
                                }}
                                items={items}
                                index={index}
                                showVars={showVars}
                                onFocus={(ref) =>
                                  onFocus({
                                    index: index,
                                    key: col.key,
                                    ref: ref,
                                  })
                                }
                                descriptionVars={descriptionVars}
                              >
                                {item[col.key]}
                              </Entry>
                            ))}
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <Controls
                      hideSum={Boolean(hideSum)}
                      hideServices={Boolean(hideServices)}
                      hideProducts={Boolean(hideProducts)}
                      hideNewPosition={Boolean(hideNewPosition)}
                      cols={columns}
                      onNewPosition={() => handleNewPosition(defaultPosition)}
                      onSelectProduct={() => setOpenProducts(true)}
                      onSelectService={() => setOpenServices(true)}
                    />
                    {!hideSum && (
                      <Sum sum={sum} cols={columns} allowImages={allowImages} />
                    )}
                  </tbody>
                </table>
              )}
            </Droppable>
            <Droppable droppableId="trash">
              {(provided, snapshot) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex"
                  sx={{
                    width: "60px",
                    height: "60px",
                  }}
                >
                  <DeleteOutlineIcon
                    className="absolute bottom-0 left-0"
                    sx={{
                      fontSize: "60px",
                      opacity: isDragging ? "1" : "0",
                      transform: isDragging ? "scale(1)" : "scale(0)",
                      color: snapshot.isDraggingOver
                        ? colors.redAccent[500]
                        : colors.primary[500],
                      transition: "0.4s",
                    }}
                  />
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
        </DragDropContext>
        <ExoDialog open={openProducts} onClose={() => setOpenProducts(false)}>
          <SelectProduct
            onSelect={handleProductSelect}
            onClose={() => setOpenProducts(false)}
          />
        </ExoDialog>
        <ExoDialog open={openServices} onClose={() => setOpenServices(false)}>
          <SelectService
            onSelect={handleServiceSelect}
            onClose={() => setOpenServices(false)}
          />
        </ExoDialog>
      </>
    );
  }
);

export default ExoPositions;
