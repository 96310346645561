import { useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { useState } from "react";
import CustomPosition from "./entries/CustomPosition";
import PredefinedPosition from "./entries/PredefinedPosition";
import { stringToNumber } from "../../special/numberConverter";
import { EntryContainer } from "./EntryContainer";

const Entry = ({
  col,
  index,
  setItems,
  items,
  onFocus,
  descriptionVars,
  showVars,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [expanded, setExpanded] = useState(false);

  if (col.hidden) return;

  function handleChange(e) {
    var newItems = items;
    if (col.number) {
      newItems[index][col.key] = stringToNumber(
        e.target.value,
        col.decimalPlaces ? col.decimalPlaces : 2
      );
    } else {
      newItems[index][col.key] = e.target.value;
    }

    setItems([...newItems]);
  }

  return (
    <EntryContainer col={col}>
      {items[index].positionableType === "custom" && (
        <CustomPosition
          onFocus={onFocus}
          col={col}
          items={items}
          index={index}
          onChange={handleChange}
          setItems={setItems}
          descriptionVars={descriptionVars}
          showVars={showVars}
        />
      )}
      {items[index].positionableType !== "custom" && (
        <PredefinedPosition
          onFocus={onFocus}
          col={col}
          items={items}
          index={index}
          onChange={handleChange}
          setItems={setItems}
        />
      )}
    </EntryContainer>
  );
};

export default Entry;
