import { useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";

export const EntryContainer = ({ children, col }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <td
      className={col.className + " align-top"}
      style={{
        width: col.width ? col.width : "max-content",
        maxWidth: col.width ? col.width : "unset",
        textAlign: col.align ? col.align : "left",
        padding: "5px 5px",
        borderTop: "1px solid " + colors.primary[400],
        borderBottom: "1px solid " + colors.primary[400],
      }}
    >
      {children}
    </td>
  );
};
